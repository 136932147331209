import React from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';

// components
import CustomInput from '../form/custom-input';
import CustomCheckbox from '../form/custom-checkbox';
import SubmitButton from '../submit-button';

// hooks
import { useRegisterFormText } from './useRegisterFormText';

// styles
import styles from './register-form.module.scss';

interface IServerErrorMessage {
  email?: string;
}

interface IProps {
  onSubmit?: () => void;
  serverErrorMessages: IServerErrorMessage;
  loading?: boolean;
}

const RegisterForm: React.FC<IProps> = ({ onSubmit, serverErrorMessages, loading }) => {
  const directusAccountRegister = useRegisterFormText();

  // state
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <CustomInput
        id="firstName"
        register={register({ required: true })}
        label={directusAccountRegister.first_name_label}
        isDirty={dirtyFields.firstName}
        error={errors.firstName}
        errorMessage="Please enter your first name"
      />

      <CustomInput
        id="email"
        type="email"
        register={register({ required: true })}
        label={directusAccountRegister.email_label}
        isDirty={dirtyFields.email}
        error={errors.email || serverErrorMessages?.email}
        errorMessage={serverErrorMessages?.email ? serverErrorMessages.email : 'Please enter your email address'}
      />

      <CustomCheckbox
        label={
          <span>
            I have read and accept the {' '}
            <Link to="/terms" className={styles.link}>
              Terms of Service
            </Link>
          </span>
        }
        id="termsOfService"
        name="termsOfService"
        register={register({ required: true })}
        error={errors.termsOfService}
        errorMessage="Please accept Terms of Service"
      />

      <SubmitButton loading={loading} buttonLabel={directusAccountRegister.submit_button_label} fullWidth />
    </form>
  );
};

export default RegisterForm;

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// services
import { formatGraphQLError } from '@services/format';
import { isValidPhoneNumber } from 'react-phone-number-input';
//queries
import { KIT_CAN_REGISTER, REGISTER_KIT } from '@queries/user';
// components
import Layout from '@components/layout';
import SEO from '@components/seo';
import SubmitButton from '@components/submit-button';
import CustomInput from '@components/form/custom-input';
import CustomCheckbox from '@components/form/custom-checkbox';
import Modal from '@components/modal';
import ModalHeader from '@components/modal/modal-header';
import ModalBody from '@components/modal/modal-body';
import ModalFooter from '@components/modal/modal-footer';
import SignInForm from '@components/sign-in-form';
import RegisterForm from '@components/register-form';
import Loader from '@components/loader';
import PhoneNumberInput from '@components/form/phone-number-input';
import Image from '@components/image/image';
// interfaces
import { GraphQLError } from '@interfaces/graphql';
// constants
import { GRAPH_QL_ERROR_TYPES } from '@constants/enums';
// context
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '@constants/errorMessages';
// hooks
import { useKitText } from '@hooks/directusHooks/useKitText';
import { useRegisterUser } from '@hooks/useRegisterUser';
import { useGetUserDetailsLazyQuery } from '@hooks/useGetUserDetails';
import useLogoutUser from '@hooks/useLogoutUser';
// utils
import { getDateWithoutTimezoneOffset } from '../utils/date';
// styles
import styles from './kit.module.scss';
import fadeTransition from '../styles/fade.module.scss';

const RegisterYourKit: React.FC = () => {
  const { directusRegisterYourKit, directusBloodConsent, directusDnaConsent } = useKitText();

  const { isLoggedIn, setErrorModalMessage } = useContext(GlobalContext);

  // state
  const [activeItem, setActiveItem] = useState();
  const [barcode, setBarcode] = useState('');
  const [isBarcodeInvalid, setIsBarcodeInvalid] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState<null | Date>(null);

  const [showUserHasAccountStep, setShowUserHasAccountStep] = useState(true);
  const [showChooseTypeStep, setShowChooseTypeStep] = useState(true);
  const [showBarcodeStep, setShowBarcodeStep] = useState(false);
  const [showYourDetailsStep, setShowYourDetailsStep] = useState(false);
  const [showEnterBarcode, setShowEnterBarcode] = useState(false);
  const [showLoggedInUserDetails, setShowLoggedInUserDetails] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showYourDetailsForm, setShowYourDetailsForm] = useState(false);
  const [showYourDetailsSuccess, setShowYourDetailsSuccess] = useState(false);
  const [errorMessages, setErrorMessages] = useState<object>({});
  const logoutUser = useLogoutUser();

  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
    control,
    watch,
    reset,
  } = useForm();

  // queries
  const [kitCanRegister, { loading: loadingKit }] = useLazyQuery(KIT_CAN_REGISTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      const canRegister = res.kit_canRegister;

      canRegister && setShowBarcodeStep(true);
      canRegister && setShowEnterBarcode(false);
      canRegister && setShowLoggedInUserDetails(true);
      canRegister ? setIsBarcodeInvalid(false) : setIsBarcodeInvalid(true);
    },
    onError: error => {
      error.message
        ? setErrorModalMessage(formatGraphQLError(error.message))
        : setErrorModalMessage(ERROR_MESSAGES.kitCanRegister);
    },
  });

  const [isMagicLinkView, setIsMagicLinkView] = useState<boolean>(false);

  const [
    getUserDetails,
    { data: dataUserDetails, loading: userLoading },
  ] = useGetUserDetailsLazyQuery();

  const userDetails = dataUserDetails?.user_details;

  const { handleRegisterUser, loading: loadingUserRegister } = useRegisterUser();

  function onError(error: GraphQLError) {
    error.message
      ? setErrorModalMessage(formatGraphQLError(error.message))
      : setErrorModalMessage(ERROR_MESSAGES.registerUser);
  }

  const [
    registerKit,
    { data: dataRegisterKit, loading: loadingRegisterKit, error: errorRegisterKit },
  ] = useMutation(REGISTER_KIT, {
    onCompleted() {
      setShowYourDetailsForm(false);
      setShowYourDetailsSuccess(true);
      setShowBarcodeStep(false);
      setShowYourDetailsStep(false);
      setShowChooseTypeStep(false);
    },
  });

  // submit functions

  const onRegisterSubmit = async (formData: any) => {
    try {
      await handleRegisterUser(formData.firstName, formData.email);
    } catch (err) {
      const findError = err.graphQLErrors.find(
        (x: GraphQLError) => GRAPH_QL_ERROR_TYPES[x.errorType]
      );
      if (findError?.errorType === GRAPH_QL_ERROR_TYPES.EmailTaken) {
        setErrorMessages({
          email: 'An account with this email already exists',
        });
      }
      onError(err);
    }
  };

  const onKitRegisterSubmit = (formData: any) => {
    kitCanRegister({
      variables: {
        type: activeItem.id,
        barcode: formData.barcode,
      },
    });

    setBarcode(formData.barcode);
  };

  const onYourDetailsSubmit = (formData: any) => {
    const dob = dateOfBirth
      ? Math.round(getDateWithoutTimezoneOffset(dateOfBirth).getTime() / 1000)
      : null;

    registerKit({
      variables: {
        type: activeItem.id,
        barcode: barcode,
        firstName: formData.firstName,
        lastName: formData.lastName,
        biologicalSex: formData.detailsGender,
        phone: formData.phone,
        dob,
      },
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUserDetails();
      setShowUserHasAccountStep(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (userDetails) {
      if (userDetails.phone && !isValidPhoneNumber(userDetails.phone)) {
        userDetails.phone = '';
      }
      reset(userDetails);
    }
  }, [userDetails, reset]);

  // fragments
  const isDna = activeItem?.id === 'dna';

  const renderConsentModal = () => (
    <CSSTransition
      in={showConsentModal}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal
        handleClose={() => {
          setShowConsentModal(false);
        }}
      >
        <ModalHeader>
          <h3>{isDna ? directusDnaConsent.title : directusBloodConsent.title}</h3>
          <hr />
        </ModalHeader>
        <ModalBody>
          {isDna ? (
            <div dangerouslySetInnerHTML={{ __html: directusDnaConsent.content }} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: directusBloodConsent.content }} />
          )}
        </ModalBody>
      </Modal>
    </CSSTransition>
  );

  const renderSignIn = () => (
    <div>
      <h3>Login</h3>
      <p>
        With the account of the person <strong>taking</strong> the test
      </p>
      <SignInForm destination={'/kit'} setIsMagicLinkView={setIsMagicLinkView} />
      {!isMagicLinkView && (
        <div className={styles.noAccount}>
          <h3>Don't have an account?</h3>
          <button
            className={styles.buttonGhost}
            onClick={() => {
              setShowRegister(true);
              setShowSignIn(false);
            }}
          >
            Register here
          </button>
        </div>
      )}
    </div>
  );

  const renderRegister = () => (
    <div>
      <h3>Create account</h3>
      <p>
        For the person <strong>taking</strong> the test
      </p>
      <RegisterForm
        onSubmit={onRegisterSubmit}
        serverErrorMessages={errorMessages}
        loading={loadingUserRegister}
      />
      <div className={styles.noAccount}>
        <h3>Already have an account?</h3>
        <button
          className={styles.buttonGhost}
          onClick={() => {
            setShowRegister(false);
            setShowSignIn(true);
          }}
        >
          Login here
        </button>
      </div>
    </div>
  );

  const renderLoginWithCorrectAccount = () => (
    <CSSTransition
      in={showConfirmModal}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setShowConfirmModal(false)}>
        <ModalBody>
          <div>
            <h2 className={styles.textCentered}>Confirm</h2>
            <hr />
            <p>You must login with the account of the person who is taking the test</p>
          </div>
        </ModalBody>
        <ModalFooter
          closeLabel="Cancel"
          saveLabel="Logout"
          handleClose={() => setShowConfirmModal(false)}
          handleSave={() => logoutUser()}
        />
      </Modal>
    </CSSTransition>
  );

  const renderBarcodeStep = () => (
    <div>
      <div className={styles.singleItemHeader}>
        <div className={styles.singleItemImage}>
          <Image
            filename={directusRegisterYourKit.barcode_icon.filename_disk}
            alt={activeItem?.name}
            width={76}
          />
        </div>
        <span>{barcode}</span>

        <button
          className={styles.buttonLink}
          type="button"
          onClick={() => {
            setShowBarcodeStep();
            setShowUserHasAccountStep();
            setShowYourDetailsStep();
            setShowYourDetailsForm();
            setShowLoggedInUserDetails();
            setShowEnterBarcode(true);
          }}
        >
          Change
        </button>
      </div>

      <hr className={styles.hrFullWidth} />
    </div>
  );

  const renderYourDetailsStep = () => (
    <div>
      <div className={styles.singleItemHeader}>
        <div className={styles.singleItemImage}>
          <Image
            filename={directusRegisterYourKit.user_icon.filename_disk}
            alt={activeItem?.name}
            width={76}
          />
        </div>
        <span>{userDetails?.email}</span>

        <button
          className={styles.buttonLink}
          type="button"
          onClick={() => setShowYourDetailsStep(false)}
        >
          Change
        </button>
      </div>

      <hr className={styles.hrFullWidth} />
    </div>
  );

  const renderLoggedInUserDetails = () => (
    <div>
      <div className={styles.userDetails}>
        <p>Signed in as:</p>
        <h3 className={styles.username}>{userDetails?.firstName}</h3>
        <p>({userDetails?.email})</p>
        <p>
          Not you?&nbsp;
          <button className={styles.buttonLink} onClick={() => logoutUser()}>
            Sign out
          </button>
        </p>
      </div>
      <h3>Who will be taking the test?</h3>
      <div className={styles.buttons}>
        <button
          className={styles.buttonGhost}
          onClick={() => {
            setShowLoggedInUserDetails(false);
            setShowYourDetailsForm(true);
            setShowYourDetailsStep(true);
          }}
        >
          Me ({userDetails?.firstName})
        </button>
        <button className={styles.buttonGhost} onClick={() => setShowConfirmModal(true)}>
          Someone else
        </button>
      </div>

      {renderLoginWithCorrectAccount()}
    </div>
  );

  const renderDoYouHaveAccount = () => (
    <div>
      <h3>Do you have a Vitl account?</h3>
      <div className={styles.buttons}>
        <button
          className={styles.buttonGhost}
          onClick={() => {
            setShowSignIn(true);
            setShowUserHasAccountStep(false);
          }}
        >
          Yes
        </button>
        <button
          className={styles.buttonGhost}
          onClick={() => {
            setShowRegister(true);
            setShowUserHasAccountStep(false);
          }}
        >
          No
        </button>
      </div>
    </div>
  );

  const renderEnterBarcode = () => (
    <div>
      <p className={styles.subheading}>{directusRegisterYourKit.find_barcode_title}</p>
      <p>{directusRegisterYourKit.find_barcode_description}</p>
      <Image filename={activeItem.image} alt={activeItem?.name} width={400} />
      <form onSubmit={handleSubmit(onKitRegisterSubmit)}>
        <fieldset disabled={loadingKit}>
          <CustomInput
            id="barcode"
            register={register({ required: true })}
            label={directusRegisterYourKit.find_barcode_placeholder}
            isDirty={dirtyFields.barcode}
            error={errors.barcode}
            errorMessage="Please enter the barcode from your kit"
          />

          {isBarcodeInvalid && <p className={styles.error}>You cannot register this barcode</p>}

          <SubmitButton buttonLabel={directusRegisterYourKit.find_barcode_button_label} fullWidth />
        </fieldset>
      </form>
    </div>
  );

  const renderYourDetailsForm = () => (
    <div>
      <h3>Your details</h3>
      <form onSubmit={handleSubmit(onYourDetailsSubmit)} className={styles.form}>
        <fieldset disabled={loadingRegisterKit}>
          <CustomInput
            id="firstName"
            register={register({ required: true })}
            label="First name"
            isDirty={dirtyFields.firstName || watch('firstName')}
            error={errors.firstName}
            errorMessage="Please enter your first name"
          />

          <CustomInput
            id="lastName"
            register={register({ required: true })}
            label="Last name"
            isDirty={dirtyFields.lastName || watch('lastName')}
            error={errors.lastName}
            errorMessage="Please enter your last name"
          />

          <h6 className={styles.radioGroupLabel}>Gender</h6>

          <div className={styles.radioButtons}>
            <div className={styles.wrap}>
              <label className={styles.radioContainer}>
                <input
                  type="radio"
                  name="detailsGender"
                  value="f"
                  ref={register({ required: true })}
                />
                <span className={styles.checkmark}></span>

                <div className={styles.radioLabel}>Female</div>
              </label>
              <label className={styles.radioContainer}>
                <input
                  type="radio"
                  name="detailsGender"
                  value="m"
                  ref={register({ required: true })}
                />
                <span className={styles.checkmark}></span>

                <div className={styles.radioLabel}>Male</div>
              </label>
            </div>
            {errors.detailsGender && (
              <small className={styles.error}>Please select your gender</small>
            )}
          </div>

          <PhoneNumberInput
            id="phone"
            autoComplete="off"
            control={control}
            countryCallingCodeEditable={false}
            error={errors.phone}
            required
          />

          <DatePicker
            selected={dateOfBirth}
            onChange={date => setDateOfBirth(date)}
            openToDate={new Date('1996/01/01')}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            showMonthDropdown
            placeholderText="Date of birth"
            required
          />

          <CustomCheckbox
            label={
              <p className={styles.acceptTerms}>
                I accept the{' '}
                <span
                  className={styles.buttonLinkLeft}
                  onClick={() => {
                    setShowConsentModal(true);
                  }}
                >
                  informed consent document
                </span>
              </p>
            }
            id="detailsConent"
            name="detailsConent"
            register={register({ required: true })}
            error={errors.detailsConent}
            errorMessage="Please accept the informed consent document"
          />

          <SubmitButton
            loading={loadingRegisterKit}
            success={dataRegisterKit}
            error={errorRegisterKit}
            errorMessage={errorRegisterKit && formatGraphQLError(errorRegisterKit)}
            buttonLabel="Register kit"
            fullWidth
          />
        </fieldset>
      </form>
    </div>
  );

  const renderYourDetailsFormSuccess = () => (
    <div>
      <h3>Thank you {userDetails?.firstName}!</h3>
      <div className={styles.grayLight}>
        <p>You have successfully registered your {activeItem?.name}</p>
        <hr />
      </div>
      <h4>Next steps</h4>
      <ul className={styles.nextSteps}>
        <li>
          <div>1</div>
          <p>Follow the rest of the instructions in your kit very carefully</p>
        </li>
        <li>
          <div>2</div>
          <p>Return your sample via a postbox in the prepaid envelope provided</p>
        </li>
        <li>
          <div>3</div>
          <p>We’ll email you when your results are ready</p>
        </li>
      </ul>
      <div className={styles.successFooter}>
        <p>Your results will be ready approximately 3 weeks after the lab receives your sample..</p>
        <p>Check your kit status at any time in your dashboard.</p>
        <Link className={styles.buttonPrimary} to={'/account/dashboard'}>
          Navigate to dashboard
        </Link>
      </div>
    </div>
  );

  if (userLoading || loadingUserRegister) return <Loader />;

  return (
    <div>
      <SEO
        title={directusRegisterYourKit.page_title}
        description={directusRegisterYourKit.page_description}
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* do you have account? */}
            {!isLoggedIn && showUserHasAccountStep && renderDoYouHaveAccount()}

            {/* sign in */}
            {!isLoggedIn && showSignIn && renderSignIn()}

            {/* register */}
            {!isLoggedIn && showRegister && renderRegister()}
          </div>

          {/* which kit do you have? */}
          {isLoggedIn && (
            <div>
              <h1>{directusRegisterYourKit.title}</h1>
              <hr />

              {!activeItem && (
                <div className={styles.wrapper}>
                  <p className={styles.subheading}>{directusRegisterYourKit.description}</p>
                  <div className={styles.items}>
                    <div
                      onClick={() => {
                        setActiveItem({
                          id: 'dna',
                          name: directusRegisterYourKit.dna_kit_title,
                          icon: directusRegisterYourKit.dna_kit_icon.filename_disk,
                          image: directusRegisterYourKit.dna_kit_image.filename_disk,
                        });
                        setShowEnterBarcode(true);
                      }}
                    >
                      <div className={styles.itemImage}>
                        <Image
                          filename={directusRegisterYourKit.dna_kit_icon.filename_disk}
                          alt={activeItem?.name}
                          width={76}
                        />
                      </div>
                      <p>{directusRegisterYourKit.dna_kit_title}</p>
                    </div>
                    <div
                      onClick={() => {
                        setActiveItem({
                          id: 'blood',
                          name: directusRegisterYourKit.blood_test_title,
                          icon: directusRegisterYourKit.blood_test_icon.filename_disk,
                          image: directusRegisterYourKit.blood_test_image.filename_disk,
                        });
                        setShowEnterBarcode(true);
                      }}
                    >
                      <div className={styles.itemImage}>
                        <Image
                          filename={directusRegisterYourKit.blood_test_icon.filename_disk}
                          alt={activeItem?.name}
                          width={76}
                        />
                      </div>
                      <p>{directusRegisterYourKit.blood_test_title}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoggedIn && activeItem && (
            <div className={styles.wrapper}>
              {showChooseTypeStep && (
                <div>
                  <div className={styles.singleItemHeader}>
                    <div className={styles.singleItemImage}>
                      <Image filename={activeItem.icon} alt={activeItem?.name} width={76} />
                    </div>
                    <span>{activeItem.name}</span>

                    <button
                      className={styles.buttonLink}
                      type="button"
                      onClick={() => {
                        setActiveItem();
                        setShowBarcodeStep(false);
                        setShowYourDetailsStep(false);
                        setShowUserHasAccountStep(false);
                        setShowLoggedInUserDetails(false);
                        setShowYourDetailsForm(false);
                      }}
                    >
                      Change
                    </button>
                  </div>

                  <hr className={styles.hrFullWidth} />
                </div>
              )}

              {showBarcodeStep && renderBarcodeStep()}
              {showYourDetailsStep && renderYourDetailsStep()}

              {showLoggedInUserDetails && renderLoggedInUserDetails()}
              {showEnterBarcode && renderEnterBarcode()}
              {showYourDetailsForm && renderYourDetailsForm()}
              {showConsentModal && renderConsentModal()}
              {showYourDetailsSuccess && renderYourDetailsFormSuccess()}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

const RegisterYourKitInLayout: React.FC = () => (
  <Layout>
    <RegisterYourKit />
  </Layout>
);

export default RegisterYourKitInLayout;

import { graphql, useStaticQuery } from 'gatsby';

export const useKitText = () => {
  const { directusRegisterYourKit, directusBloodConsent, directusDnaConsent } = useStaticQuery(graphql`
    query RegisterYourKit {
      directusRegisterYourKit {
        page_title
        page_description
        title
        description
        find_barcode_title
        find_barcode_description
        find_barcode_placeholder
        find_barcode_button_label
        dna_kit_title
        dna_kit_icon {
          title
          filename_disk
        }
        dna_kit_image {
          title
          filename_disk
        }
        blood_test_title
        blood_test_icon {
          title
          filename_disk
        }
        blood_test_image {
          title
          filename_disk
        }
        barcode_icon {
          filename_disk
        }
        user_icon {
          filename_disk
        }
      }
      directusBloodConsent {
        title
        content
      }
      directusDnaConsent {
        title
        content
      }
    }
  `);
  return {
    directusRegisterYourKit,
    directusBloodConsent,
    directusDnaConsent,
  };
};

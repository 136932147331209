import { graphql, useStaticQuery } from 'gatsby';

export const useRegisterFormText = () => {
  const content = useStaticQuery(graphql`
    query RegisterForm {
      directusAccountRegister {
        first_name_label
        email_label
        submit_button_label
      }
    }
  `);
  return content.directusAccountRegister;
};
